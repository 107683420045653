import React from "react";
import { FreshersPictureHeroSection } from "@components/FreshersPictureHeroSection";
import { SiteButton } from "@components/SiteClickable";

// markup
const FreshersPage = () => {
  return (
    <FreshersPictureHeroSection height="fullscreen">
      <SiteButton
        className="btn-solid-red m-1"
        target="_blank"
        to="/events/freshers2022"
      >
        See upcoming events
      </SiteButton>
    </FreshersPictureHeroSection>
  );
};

export default FreshersPage;
